
import { defineComponent } from 'vue';
import Button from '../../../atomic/atoms/Button/Button.vue';
import Link from '../../../atomic/atoms/Link/Link.vue';
import Input from '../../../atomic/atoms/Input/Input.vue';
import AuthTemplate from '../../../atomic/templates/AuthTemplate/AuthTemplate.vue';
import { FormBuilder, FormControl } from '../../../utilities/Forms';
import { Validators } from '../../../utilities/Validators/Validators';
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  components: {
    'auth-template': AuthTemplate,
    'atomic-button': Button,
    'atomic-link': Link,
    'atomic-input': Input,
  },

  data(): {
    inputType: string;
    formData: FormBuilder;
  } {
    return {
      inputType: 'password',
      formData: new FormBuilder({
        passwordOne: new FormControl('', [Validators.password, Validators.required]),
        passwordTwo: new FormControl('', [Validators.password, Validators.required]),
      }),
    };
  },

  mounted() {
    if (!this.emailAddress) {
      this.$router.push('login');
    }
  },

  computed: {
    ...mapGetters(['emailAddress']),
  },

  methods: {
    ...mapActions(['setPassword']),
    async submitForm(): Promise<void> {
      this.formData.markAllAsTouched();

      if (this.formData.controls.passwordOne.value !== this.formData.controls.passwordTwo.value) {
        this.$notify({
          title: 'SOMETHING LOOKS OFF',
          text: 'The passwords entered must match.',
          type: 'error',
          duration: 5000,
        });

        return;
      }

      if (this.formData.hasErrors) {
        return;
      }

      const passwordReset = await this.setPassword({ email: this.emailAddress, password: this.formData.controls.passwordOne.value });

      if (!passwordReset) {
        this.$notify({
          title: 'SOMETHING LOOKS OFF',
          text: 'We were unable to process this request. Try again later.',
          type: 'error',
          duration: 5000,
        });
      } else {
        this.$notify({
          title: 'PASSWORD RESET',
          text: 'We successfully reset your password. Please login with your new credentials.',
          type: 'success',
          duration: 5000,
        });

        setTimeout(() => {
          this.$router.push('login');
        }, 2000);
      }
    },
    validateControl(controlName: string): void {
      this.formData.controls[controlName].validate();
    },
    toggleShow(): void {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
  },
});
